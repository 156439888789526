<template>
  <vs-button color="primary" type="border" class="mb-4 md:mb-0" @click="generatePDF()">พิมพ์ใบสัญญา</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'
  import { font } from "./assets/THSarabunNew-normal";
  import { font_bold } from "./assets/THSarabunNew-bold";

  export default {
    components: {
      jsPDF,
    },
    name: "RequestForm",
    props: ['createMember', 'data', 'org'],
    data() {
      return {}
    },
    methods: {
      generatePDF() {
        if(this.createMember) {
          const data = this.data
          const doc = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [279.4, 210]
          });
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew','bold');
          doc.setFontSize(16);
          doc.text('สัญญาการใช้น้ำประปา', doc.internal.pageSize.getWidth()/2, 30, {align: 'center'});
          doc.setFont('THSarabunNew','normal');
          doc.setFontSize(14);
          doc.text('เลขที่ ......................./........................', 133, 30);
          doc.text('เขียนที่', 133, 36);
          doc.text(this.org.Org02, 145, 36);
          doc.text(this.org.Org03, 145, 41);
          doc.autoTable({
            styles: {font: 'THSarabunNew', fontSize: 14 },
            margin: { left: 30, top: 45, right: 30},
            theme: 'plain',
            tableWidth: 150,
            columnStyles: {
              0: {cellWidth: 75}
            },
            body: [
              [{ content: data.textContract, colSpan: 2 }],
              [' ','(ลงชื่อ) .......................................................... ผู้ให้สัญญา'],
              [' ', { content: '( ' + data.recipientRequest + ' )', styles: {halign: 'center'} }],
              [' ','(ลงชื่อ) .......................................................... ผู้รับสัญญา'],
              [' ', { content: '( ' + data.contractor + ' )', styles: {halign: 'center'} }],
              [' ','(ลงชื่อ) .......................................................... พยาน'],
              [' ', { content: '( ' + data.attestor1 + ' )', styles: {halign: 'center'} }],
              [' ','(ลงชื่อ) .......................................................... พยาน'],
              [' ', { content: '( ' + data.attestor2 + ' )', styles: {halign: 'center'} }],
            ]
          })
          doc.save('ใบสัญญา.pdf');
        } else {
          this.dialog('warning', 'คำเตือน', 'กรุณากรอกแบบคำขอใช้น้ำ')
        }
      },
      dialog(type, title, message) {
        this.$vs.dialog({
          type: 'alert',
          color: type,
          title: title,
          text: message,
          acceptText: 'ปิด'
        })
      }
    }
  }
</script>

<style>

</style>
