<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card class="mb-8">
        <div class="vx-row">
          <div class="vx-col sm:w-1/4 w-full">
            <span>ชื่อผู้ใช้น้ำ</span>
            <vs-input
              v-model="search"
              @keydown="onKeydown($event)"
              style="width: 170px; display: inline-block; margin-left: 10px"
            />
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <span class="mr-6">รายชื่อ</span>
            <v-select
              :options="memberOpt"
              :clearable="false"
              @input="setMember"
              v-model="selectedMember"
              class="mb-4 md:mb-0 w-2/3"
              style="display: inline-block; width: 90%"
            >
              <template #no-options="{ memberOpt }"> ไม่พบข้อมูล </template>
            </v-select>
          </div>
        </div>
      </vx-card>

      <vx-card title="แบบคำขอใช้น้ำ">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-button color="warning" type="filled" @click="popupEdit = true">
              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base">แก้ไขใบสัญญา</span>
            </vs-button>
            <vs-popup
              title="แก้ไขใบสัญญา"
              :active.sync="popupEdit"
              class="edit-popup"
            >
              <div class="content-popup pt-0">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <vs-divider position="center" class="mt-0"
                    >ข้อความในใบสัญญา</vs-divider
                    >
                    <div class="vx-row">
                      <div class="vx-col w-full">
                        <vs-textarea
                          v-model="form.textContract"
                          class="text-area"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-popup vx-row mt-2">
                <div class="vx-col sm:w-1/2 text-right w-full">
                  <vs-button
                    @click="popupEdit = false"
                    class="btn-close-popup"
                    color="danger"
                    type="filled"
                  >ยกเลิก</vs-button
                  >
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-button @click="editPopup()" color="primary" type="filled"
                  >บันทึก</vs-button
                  >
                </div>
              </div>
            </vs-popup>
          </div>
          <div class="vx-col sm:w-1/2 w-full text-right">
            <ContractPDF
              class="mr-3"
              :createMember="createMember"
              :data="saveForm"
              :org="org"
            />
            <RequestPDF
              :createMember="createMember"
              :data="saveForm"
              :org="org"
            />
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชื่อสมาชิก</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  name="name"
                  v-model="form.M04"
                />
                <span class="text-danger text-sm" v-show="errors.has('name')"
                >กรุณากรอกชื่อ-นามสกุลสมาชิก</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/5 w-full">
                <span>อายุ</span>
              </div>
              <div class="vx-col sm:w-1/5 w-full">
                <vs-input class="w-full" v-model="form.age" />
              </div>
              <div class="vx-col sm:w-1/5 w-full">
                <span>ปี</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>บ้านเลขที่</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  name="addressNo"
                  v-model="form.M05"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('addressNo')"
                >กรุณากรอกบ้านเลขที่</span
                >
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ซอย</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.soi" />
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ถนน</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.road" />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/6 w-full">
                <span>ที่อยู่</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-5/6 w-full">
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  name="address"
                  v-model="form.M06"
                />
                <span class="text-danger text-sm" v-show="errors.has('address')"
                >กรุณากรอกที่อยู่</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลขที่บัตรประชาชน</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.idCardNumber" />
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>วันหมดอายุ</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  :language="languages[language]"
                  format="dd MMMM yyyy"
                  v-model="form.expiredIdCardNumber"
                ></datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>อาชีพ</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.career" />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ที่ทำงาน</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.workspace" />
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เบอร์โทร</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.telWorkspace" />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/6 w-full">
                <span>มีความประสงค์ที่จะ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-5/6 w-full">
                <v-select
                  :options="request"
                  :value="request"
                  v-validate="'required'"
                  name="request"
                  :clearable="false"
                  v-model="form.selectedRequest"
                  class="mb-4 md:mb-0"
                />
                <span class="text-danger text-sm" v-show="errors.has('request')"
                >กรุณาเลือความประสงค์</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ดำเนินการที่</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.requestAt" />
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>สถานที่ใกล้เคียง</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.nearbyPlaces" />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ขนาดท่อภายใน</span>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-input class="w-full" v-model="form.innerTubeSize" />
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <span>นิ้ว</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ก๊อกน้ำขนาด</span>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-input class="w-full" v-model="form.waterFaucetSize" />
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <span>นิ้ว</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>จำนวน</span>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-input class="w-full" v-model="form.number" />
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <span>ตัว</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ผู้รับคำขอ</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.recipientRequest" />
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ลงวันที่</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  :language="languages[language]"
                  format="dd MMMM yyyy"
                  v-model="form.dateContract"
                ></datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/6 w-full">
                <span>สถานะการดำเนินงาน</span>
              </div>
              <div class="vx-col sm:w-5/6 w-full">
                <vs-checkbox v-model="form.checkboxStatus"
                >เสร็จ/จบงาน</vs-checkbox
                >
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/1 w-full">
            <div class="vx-row mt-3">
              <div class="vx-col sm:w-1/6 w-full">
                <span>ผู้รับสัญญา (ชื่อและตำแหน่ง)</span>
              </div>
              <div class="vx-col sm:w-5/6 w-full">
                <vs-input class="w-full" v-model="form.contractor" />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>พยาน 1</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.attestor1" />
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>พยาน 2</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="form.attestor2" />
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mt-6">
          <div class="vx-col sm:w-1/1 w-full text-right">
            <vs-button class="mb-2" @click="save()"
            >บันทึกแบบคำขอใช้น้ำ</vs-button
            >
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import vSelect from "vue-select";
  import Datepicker from "vuejs-datepicker";
  import * as lang from "vuejs-datepicker/src/locale";
  import moduleMember from "@/store/member/moduleMember.js";
  import moduleReq from "@/store/req/moduleReq.js";
  import moduleConfig from "@/store/config/moduleConfig.js";
  import RequestPDF from "../../pdf/RequestFormReport";
  import ContractPDF from "../../pdf/RequestContractReport";

  export default {
    components: {
      Datepicker,
      vSelect,
      RequestPDF,
      ContractPDF,
    },
    data() {
      return {
        language: "th",
        languages: lang,
        request: [
          { label: "ขอติดตั้งมาตรวัดน้ำ", value: "ขอติดตั้งมาตรวัดน้ำ" },
          { label: "ขอวางท่อ", value: "ขอวางท่อ" },
          { label: "ขอใช้น้ำประปาชั่วคราว", value: "ขอใช้น้ำประปาชั่วคราว" },
          { label: "ขอโอนประเภทการใช้น้ำ", value: "ขอโอนประเภทการใช้น้ำ" },
          { label: "อื่นๆ", value: "อื่นๆ" },
          { label: "ในเขต", value: "ในเขต" },
          { label: "นอกเขต", value: "นอกเขต" },
        ],
        popupEdit: false,
        reqId: '',
        saveForm: {
          M04: "",
          M05: "",
          soi: "",
          road: "",
          M06: "",
          age: "",
          idCardNumber: "",
          expiredIdCardNumber: "",
          career: "",
          workspace: "",
          telWorkspace: "",
          requestAt: "",
          nearbyPlaces: "",
          innerTubeSize: "",
          waterFaucetSize: "",
          number: "",
          recipientRequest: "",
          checkboxStatus: false,
          contractor: "",
          dateContract: "",
          attestor1: "",
          attestor2: "",
          textContract: "",
          selectedRequest: "",
        },
        form: {
          M04: "",
          M05: "",
          soi: "",
          road: "",
          M06: "",
          age: "",
          idCardNumber: "",
          expiredIdCardNumber: "",
          career: "",
          workspace: "",
          telWorkspace: "",
          requestAt: "",
          nearbyPlaces: "",
          innerTubeSize: "",
          waterFaucetSize: "",
          number: "",
          recipientRequest: "",
          checkboxStatus: false,
          contractor: "",
          dateContract: "",
          attestor1: "",
          attestor2: "",
          textContract: "",
          selectedRequest: "",
        },
        createMember: false,
        search: '',
        memberOpt: [],
        selectedMember: {label: 'ไม่พบข้อมูล', value: '', index: '', data: ''},
      };
    },
    computed: {
      org() {
        return this.$store.state.config.item;
      },
    },
    methods: {
      init() {
        const localData = localStorage.getItem("dataTextContract");
        if (localData !== null && localData !== "" && localData !== undefined) {
          this.form.textContract = localData;
        } else  {
          this.form.textContract = ''
        }
      },
      onKeydown(e) {
        if (e.keyCode == 13 || e.which == 13) { //Enter keycode
          const body = {
            RQ02: this.search
          };
          this.getReqFilter(body);
        }
      },
      editPopup() {
        localStorage.setItem("dataTextContract", this.form.textContract);
        this.popupEdit = false;
      },
      setMember(item) {
        this.reqId = item.value
        this.form = {
          M04: item.data.RQ02,
          M05: item.data.RQ04,
          soi: item.data.soi,
          road: item.data.road,
          M06: item.data.address,
          age: item.data.RQ03,
          idCardNumber: item.data.RQ05,
          expiredIdCardNumber: item.data.RQ06,
          career: item.data.RQ07,
          workspace: item.data.RQ08,
          telWorkspace: item.data.RQ09,
          requestAt: item.data.RQ11,
          nearbyPlaces: item.data.RQ12,
          innerTubeSize: item.data.RQ13,
          waterFaucetSize: item.data.RQ14,
          number: item.data.RQ15,
          recipientRequest: item.data.RQ16,
          checkboxStatus: item.data.RQ18,
          contractor: item.data.RQ19,
          dateContract: item.data.RQ17,
          attestor1: item.data.RQ20,
          attestor2: item.data.RQ21,
          selectedRequest: item.data.RQ10,
          textContract: this.form.textContract
        }
        this.saveFormPDF()
      },
      async getReqFilter(payload) {
        this.$vs.loading();
        await this.$store.dispatch("req/fetchDataListByFilter", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const member = res.data;
            let options = [];
            for (let i = 0; i < member.length; i++) {
              let soi = ''
              let road = ''
              if(member[i].soi !== undefined && member[i].soi !== null) {
                soi = member[i].soi
              }
              if(member[i].road !== undefined && member[i].road !== null) {
                road = member[i].road
              }
              const name =  member[i].RQ02 + ' ' + member[i].RQ04 + ' ' + soi + ' ' + road
              options.push({
                'label': name,
                'value': member[i]._id,
                'data': member[i],
                'index': i
              });
            }
            this.memberOpt = options;
            this.selectedMember = {
              label: options[0].label,
              value: options[0].value,
              data: options[0].data,
              index: 0
            };
            this.setMember(this.selectedMember)
          } else {
            this.clearForm()
            this.memberOpt = [];
            this.selectedMember = {
              label: 'ไม่พบข้อมูล',
              value: '',
              data: ''
            };
          }
          this.$vs.loading.close();
        }).catch((error) => {
          this.clearForm()
          this.memberOpt = [];
          this.selectedMember = {
            label: 'ไม่พบข้อมูล',
            value: '',
            data: ''
          };
          this.$vs.loading.close();
        });
      },
      async save() {
        this.createMember = false;
        await this.$validator.validateAll().then((result) => {
          if (result) {
            const payload = {
              RQ02: this.form.M04,
              RQ03: this.form.age,
              RQ04: this.form.M05,
              RQ05: this.form.idCardNumber,
              RQ06: this.form.expiredIdCardNumber,
              RQ07: this.form.career,
              RQ08: this.form.workspace,
              RQ09: this.form.telWorkspace,
              RQ10: this.form.selectedRequest.label,
              RQ11: this.form.requestAt,
              RQ12: this.form.nearbyPlaces,
              RQ13: this.form.innerTubeSize,
              RQ14: this.form.waterFaucetSize,
              RQ15: this.form.number,
              RQ16: this.form.recipientRequest,
              RQ17: this.form.dateContract,
              RQ18: this.form.checkboxStatus,
              RQ19: this.form.contractor,
              RQ20: this.form.attestor1,
              RQ21: this.form.attestor2,
              soi: this.form.soi,
              road: this.form.road,
              address: this.form.M06
            };
            if(this.reqId === '') {
              this.create(payload)
            } else {
              this.edit(payload)
            }
          } else {
            this.dialog("warning", "คำเตือน", "กรุณากรอกข้อมูลให้ครบ");
          }
        });
      },
      create(payload) {
        this.$store.dispatch("req/addItem", payload).then((res) => {
          if (res.status === 201 || res[0].status === 201) {
            this.saveFormPDF()
            this.dialog("success", "สำเร็จ", "บันทึกแบบคำขอใช้น้ำสำเร็จ");
          } else {
            this.createMember = false;
            this.dialog(
              "danger",
              "ผิดพลาด",
              "บันทึกแบบคำขอใช้น้ำไม่สำเร็จ"
            );
          }
        })
          .catch((error) => {
            this.createMember = false;
            this.dialog("danger", "ผิดพลาด", "บันทึกแบบคำขอใช้น้ำไม่สำเร็จ");
          });
      },
      edit(payload) {
        payload.id = this.reqId
        this.$store.dispatch("req/updateItem", payload).then((res) => {
          if (res.status === 200 || res[0].status === 200) {
            this.memberOpt[this.selectedMember.index] = res.data
            this.saveFormPDF()
            this.dialog("success", "สำเร็จ", "บันทึกแบบคำขอใช้น้ำสำเร็จ");
          } else {
            this.createMember = false;
            this.dialog(
              "danger",
              "ผิดพลาด",
              "บันทึกแบบคำขอใช้น้ำไม่สำเร็จ"
            );
          }
        })
          .catch((error) => {
            this.createMember = false;
            this.dialog("danger", "ผิดพลาด", "บันทึกแบบคำขอใช้น้ำไม่สำเร็จ");
          });
      },
      clearForm() {
        this.$validator.reset()
        this.reqId = ''
        this.form = {
          M04: "",
          M05: "",
          soi: "",
          road: "",
          M06: "",
          age: "",
          idCardNumber: "",
          expiredIdCardNumber: "",
          career: "",
          workspace: "",
          telWorkspace: "",
          requestAt: "",
          nearbyPlaces: "",
          innerTubeSize: "",
          waterFaucetSize: "",
          number: "",
          recipientRequest: "",
          checkboxStatus: false,
          contractor: "",
          dateContract: "",
          attestor1: "",
          attestor2: "",
          textContract: "",
          selectedRequest: ""
        }
      },
      saveFormPDF() {
        this.createMember = true;
        this.saveForm = {
          M04: this.form.M04,
          M05: this.form.M05,
          soi: this.form.soi,
          road: this.form.road,
          M06: this.form.M06,
          age: this.form.age,
          idCardNumber: this.form.idCardNumber,
          expiredIdCardNumber: this.form.expiredIdCardNumber,
          career: this.form.career,
          workspace: this.form.workspace,
          telWorkspace: this.form.telWorkspace,
          requestAt: this.form.requestAt,
          nearbyPlaces: this.form.nearbyPlaces,
          innerTubeSize: this.form.innerTubeSize,
          waterFaucetSize: this.form.waterFaucetSize,
          number: this.form.number,
          recipientRequest: this.form.recipientRequest,
          checkboxStatus: this.form.checkboxStatus,
          contractor: this.form.contractor,
          dateContract: this.form.dateContract,
          attestor1: this.form.attestor1,
          attestor2: this.form.attestor2,
          textContract: this.form.textContract,
          selectedRequest: this.form.selectedRequest,
        };
      },
      dialog(type, title, message) {
        this.$vs.dialog({
          type: "alert",
          color: type,
          title: title,
          text: message,
          acceptText: "ปิด",
        });
      },
    },
    async created() {
      if (!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
      if (!moduleReq.isRegistered) {
        this.$store.registerModule("req", moduleReq);
        moduleReq.isRegistered = true;
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule("config", moduleConfig);
        moduleConfig.isRegistered = true;
      }
      await this.$store.dispatch("config/fetchDataItem");
      this.init();
    },
  };
</script>

<style lang="scss">
  .text-area {
    height: 545px;
    overflow-y: scroll;
  }
  .text-area textarea {
    height: 100%;
  }
</style>
