<template>
  <vs-button color="primary" type="border" class="mb-4 md:mb-0" @click="generatePDF()">พิมพ์แบบคำขอใช้น้ำ</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'
  import { font } from "./assets/THSarabunNew-normal";
  import { font_bold } from "./assets/THSarabunNew-bold";
  import { square } from "./assets/square";
  import moment from "moment";

  export default {
    components: {
      jsPDF,
    },
    name: "RequestForm",
    props: ['createMember', 'data', 'org'],
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      thaiNumber(num){
        var array = {"1":"๑", "2":"๒", "3":"๓", "4" : "๔", "5" : "๕", "6" : "๖", "7" : "๗", "8" : "๘", "9" : "๙", "0" : "๐"};
        var str = num.toString();
        for (var val in array) {
          str = str.split(val).join(array[val]);
        }
        return str;
      },
      dateFormatTh(val) {
        const date = moment(val).locale("th");
        date.add(543, "years");
        const form = date.format("DD/MM/YYYY");
        const split = form.split('/')
        const day = this.thaiNumber(parseInt(split[0]))
        const month = this.thmonth[parseInt(split[1])]
        const year = this.thaiNumber(split[2])
        return day + ' ' + month + ' ' + year
      },
      generatePDF() {
        if(this.createMember) {
          const data = this.data
          const doc = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: [279.4, 210]
          });
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew','bold');
          doc.setFontSize(16);
          doc.text('แบบคำขอการใช้น้ำประปา', 30, 36);
          doc.line(30, 36, 70, 36)
          doc.setFont('THSarabunNew','normal');
          doc.setFontSize(14);
          doc.addImage(square, 'PNG', 150, 8, 8, 8);
          doc.text('ข้อ ๑           ขอติดตั้งมาตรวัดน้ำ       นิ้ว', 141, 15);
          doc.addImage(square, 'PNG', 150, 17, 8, 8);
          doc.text('ข้อ ๒           ขอวางท่อ', 141, 24);
          doc.addImage(square, 'PNG', 150, 26, 8, 8);
          doc.text('ข้อ ๓           ขอใช้น้ำประปาชั่วคราว', 141, 33);
          doc.addImage(square, 'PNG', 150, 35, 8, 8);
          doc.text('ข้อ ๔           ขอโอนประเภทการใช้น้ำ', 141, 42);
          doc.addImage(square, 'PNG', 150, 44, 8, 8);
          doc.text('ข้อ ๕           อื่นๆ', 141, 51);
          doc.addImage(square, 'PNG', 150, 53, 8, 8);
          doc.addImage(square, 'PNG', 170, 53, 8, 8);
          doc.text('ข้อ ๖           ในเขต           นอกเขต', 141, 60);
          doc.setFont('THSarabunNew','bold');
          doc.text('เขียนที่', 130, 75);
          doc.setFont('THSarabunNew','normal');
          doc.text(this.org.Org02, 143, 75);
          doc.text(this.org.Org03, 143, 81);
          let dateContract = ''
          if(data.dateContract !== '' && data.dateContract !== null && data.dateContract !== undefined) {
            dateContract = this.dateFormatTh(data.dateContract)
          }
          doc.text('วันที่ ' + dateContract, doc.internal.pageSize.getWidth()/2, 90, {align: 'center'});
          doc.setFont('THSarabunNew','bold');
          doc.text('ข้าพเจ้า .......................................................................................................................... อายุ ......................... ปี', 41, 100);
          doc.text('ที่อยู่ ............................................................................................................... บัตรประจำตัวประชาชน .......................................', 20, 108);
          doc.text('วันหมดอายุ ........................................ อาชีพ ................................................... ที่ทำงาน ............................................................', 20, 116);
          doc.text('โทรศัพท์ .....................................................', 20, 124);
          doc.text('มีความประสงค์จะดำเนินการ ................................................................................................................................', 41, 132);
          doc.text('สำหรับ ............................................................................... สถานที่ใกล้เคียง ................................................................................', 20, 140);
          doc.text('โดยทีการ ต่อท่อภายในขนาดเส้นผ่าศูนย์กลาง ...................... นิ้ว  และมีก๊อกน้ำขนาด ...................... นิ้ว จำนวน ............... ก๊อก', 20, 148);
          doc.setFont('THSarabunNew','normal');
          doc.text('ข้าพเจ้ายินดีให้ความสะดวกแก่เจ้าหน้าที่ของ ' + this.org.Org02 + ' ในการสำรวจและปฏิบัติตามเทศบัญญัติ', 41, 160);
          doc.text('ของ ' + this.org.Org02 + ' เกี่ยวกับการขอรับใช้น้ำประปาทุกประการ ซึ่งเจ้าหน้าที่ได้ชี้แจงให้ทราบแล้ว ในการดำเนินการ', 20, 168);
          doc.text('ติดตั้งนี้ ข้าพเจ้าได้ใช้จ่ายในการติดตั้งประปาตามที่ ' + this.org.Org02 + ' กำหนดเรียบร้อยแล้ว', 20, 176);
          doc.text('อาศัย             อาศัย/ประกอบการ             ประกอบการ', 29, 186);
          doc.addImage(square, 'PNG', 20, 179, 8, 8);
          doc.addImage(square, 'PNG', 41, 179, 8, 8);
          doc.addImage(square, 'PNG', 80, 179, 8, 8);
          doc.text('ข้าพเจ้ายินยอมจ่ายค่าขยะ', 29, 196);
          doc.addImage(square, 'PNG', 20, 189, 8, 8);
          doc.setFont('THSarabunNew','bold');
          doc.text('เอกสารประกอบการยื่นคำขอ', 20, 206);
          doc.setFont('THSarabunNew','normal');
          doc.text('บัตรประจำตัวประชาชน', 29, 216);
          doc.text('สำเนาทะเบียนบ้าน', 29, 226);
          doc.text('หลักฐานอื่นๆ', 29, 236);
          doc.addImage(square, 'PNG', 20, 209, 8, 8);
          doc.addImage(square, 'PNG', 20, 219, 8, 8);
          doc.addImage(square, 'PNG', 20, 229, 8, 8);
          doc.text('(ลงชื่อ) ........................................................ ผู้ขอรับใช้น้ำประปา', 110, 250);
          doc.text('( ' + data.recipientRequest + ' )', 143, 260, {align: 'center'});
          doc.text(data.M04, 57, 99);
          doc.text(data.age, 175, 99, {align: 'center'});
          const splitM05 = data.M05.split('/')
          let addressNo = ''
          for(let i = 0; i < splitM05.length; i++) {
            addressNo += this.thaiNumber(splitM05[i])
            if(i < splitM05.length - 1) {
              addressNo += '/'
            }
          }
          doc.text(addressNo + ' ' + data.soi + ' ' + data.road + ' ' + data.M06, 30, 107);
          let cardNumber = ''
          if(data.idCardNumber !== '' && data.idCardNumber !== null && data.idCardNumber !== undefined) {
            cardNumber = this.thaiNumber(data.idCardNumber)
          }
          doc.text(cardNumber, 172, 107, {align: 'center'});
          let expiredIdCardNumber = ''
          if(data.expiredIdCardNumber !== '' && data.expiredIdCardNumber !== null && data.expiredIdCardNumber !== undefined) {
            expiredIdCardNumber = this.dateFormatTh(data.expiredIdCardNumber)
          }
          doc.text(expiredIdCardNumber, 54, 115, {align: 'center'});
          doc.text(data.career, 102, 115, {align: 'center'});
          let workspace = ''
          if(data.workspace !== '' && data.workspace !== null && data.workspace !== undefined) {
            workspace = this.dateFormatTh(data.workspace)
          }
          doc.text(workspace, 162, 115, {align: 'center'});
          doc.text(data.telWorkspace, 54, 123, {align: 'center'});
          doc.text(data.selectedRequest.label, 85, 131);
          let requestAt = ''
          if(data.requestAt !== '' && data.requestAt !== null && data.requestAt !== undefined) {
            requestAt = this.dateFormatTh(data.requestAt)
          }
          doc.text(requestAt, 35, 139);
          doc.text(data.nearbyPlaces, 125, 139);
          let innerTubeSize = ''
          if(data.innerTubeSize !== '' && data.innerTubeSize !== null && data.innerTubeSize !== undefined) {
            innerTubeSize = this.thaiNumber(data.innerTubeSize)
          }
          doc.text(innerTubeSize, 91, 147, {align: 'center'});
          let waterFaucetSize = ''
          if(data.waterFaucetSize !== '' && data.waterFaucetSize !== null && data.waterFaucetSize !== undefined) {
            waterFaucetSize = this.thaiNumber(data.waterFaucetSize)
          }
          doc.text(waterFaucetSize, 144, 147, {align: 'center'});
          let number = ''
          if(data.number !== '' && data.number !== null && data.number !== undefined) {
            number = this.thaiNumber(data.number)
          }
          doc.save('แบบคำขอใช้น้ำ.pdf');
        } else {
          this.dialog('warning', 'คำเตือน', 'กรุณากรอกแบบคำขอใช้น้ำ')
        }
      },
      dialog(type, title, message) {
        this.$vs.dialog({
          type: 'alert',
          color: type,
          title: title,
          text: message,
          acceptText: 'ปิด'
        })
      }
    }
  }
</script>

<style>

</style>
